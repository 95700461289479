import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Page from '../templates/Page'
import {
  Center,
  SmallLine,
  colors,
  TwoColumnGrid,
  Spacer,
} from '../utils/styles'
import { StaticImage } from 'gatsby-plugin-image'

const MediaPage = ({ data: { heroImage } }) => {
  const { t } = useTranslation()
  return (
    <Page
      title={t('BE A PART WITH')}
      subtitle={t('NIGHTVISION')}
      headerImage={heroImage}
    >
      <Center>
        <StaticImage
          loading="eager"
          src="../images/Youtube_Icon_green.png"
          height={64}
          alt=""
        />
        <SmallLine color={colors.androidGreen} />
      </Center>
      <TwoColumnGrid>
        <Center>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KtxctigrUBI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Center>
        <Center>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4UZ3J42OxN4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Center>
        <Center>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/pquEu7ayQnQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Center>
        <Center>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/uNHR3elaLWU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Center>
      </TwoColumnGrid>
      <Spacer height="5rem" />
    </Page>
  )
}

export const query = graphql`
  query ($language: String!) {
    heroImage: file(
      name: { eq: "media-header" }
      sourceInstanceName: { eq: "images" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
          aspectRatio: 4
        )
      }
    }
    i18nLocales: allLocale(
      filter: { ns: { in: ["common", "media"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default MediaPage
